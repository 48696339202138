import Vue from 'vue';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronCircleDown, faInfoCircle, faQuestionCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronCircleDown, faInfoCircle, faQuestionCircle, faPlusCircle);


izele.vueApps.frontPageVM = new Vue({
    el: '#front-page',
    components: {},
    data: {},
    methods: {
        scrollToElement: function (event) {
            $('html, body').animate({
                scrollTop: $(event.target).offset().top
            });
        }
    },
    computed: {},
    mounted: function () {}
});
